import { useSelector, useDispatch } from 'react-redux';
import { setUser, initialState } from 'store/auth/userSlice';
import { apiSignIn, apiSignOut, apiSignResetPassword } from 'services/AuthService';
import { onSignInSuccess, onSignOutSuccess, setSignedIn, setToken } from 'store/auth/sessionSlice';
import appConfig from 'configs/app.config';
import { REDIRECT_URL_KEY } from 'constants/app.constant';
import { useNavigate } from 'react-router-dom';
import useQuery from './useQuery';
import { useEffect } from 'react';

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn, website } = useSelector((state) => state.auth.session);

  const signIn = async (values) => {
    try {
      const resp = await apiSignIn(values);
      if (resp.data && resp.data.changePassword !== true) {
        const { token, expiration } = resp.data;
        
        if (resp.data.hasSetup2Fa) {
          dispatch(setToken({token:token, expiration: expiration}));
          navigate('/loginWith2fa');
        } else {
          dispatch(onSignInSuccess({token:token, expiration: expiration}));
          dispatch(
            setUser(
              resp.data.user || {
                avatar: '',
                userName: 'Anonymous',
                authority: ['USER'],
                email: '',
                firstname: '',
                lastname: '',
                userId: '',
              }
            )
          );
        }
        return {
          status: 'success',
          message: '',
        };
      } else if (resp.data && resp.data.changePassword === true) {
        const { token, expiration } = resp.data;
        dispatch(setToken({token:token, expiration: expiration}));
        if (resp.data.user) {
          dispatch(
            setUser(
              resp.data.user || {
                avatar: '',
                userName: 'Anonymous',
                authority: ['USER'],
                email: '',
                firstname: '',
                lastname: '',
                userId: '',
              }
            )
          );
        }
        navigate('/change-password');
        return {
          status: 'success',
          message: '',
        };
        // dispatch(onSignInSuccess(token))
      }
    } catch (errors) {
      return {
        status: 'failed',
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const resetPassword = async (values) => {
    try {
      const resp = await apiSignResetPassword(values);
      if (resp.data) {
        // const { token } = resp.data
        dispatch(setSignedIn(true));
        navigate(appConfig.authenticatedEntryPath);
        return {
          status: 'success',
          message: '',
        };
      }
    } catch (errors) {
      return {
        status: 'failed',
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  // useEffect(() => {
  // 	if (signedIn === false) {
  // 		window.location.reload();
  // 	}
  // }, [])

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
    window.location.reload();
  };

  const signOut = async () => {
    await apiSignOut();
    handleSignOut();
  };

  return {
    authenticated: token && signedIn,
    signIn,
    resetPassword,
    signOut,
  };
}

export default useAuth;
