import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import reducer from './store';
import { injectReducer } from 'store/index';
import { apiGetThemeConfig } from 'services/HomeServices';
import {
  THEME_ENUM
} from 'constants/theme.constant';
import {
  setThemeColor,
  setLang,
  setLayout,
  setThemeColorLevel,
  setMode,
  setNavMode
} from 'store/theme/themeSlice'

injectReducer('themeList', reducer);

const defaultMenuData = {
  theme: {
    themeColor: 'indigo',
    direction: THEME_ENUM.DIR_LTR,
    mode: THEME_ENUM.MODE_LIGHT,
    locale: 'sq',
    primaryColorLevel: 600,
    cardBordered: true,
    panelExpand: false,
    controlSize: 'md',
    navMode: THEME_ENUM.NAV_MODE_LIGHT,
    layout: {
      type: THEME_ENUM.LAYOUT_TYPE_MODERN,
      sideNavCollapse: false,
    },
  },
};

export default function GetThemeConfig() {
  const dispatch = useDispatch();
  const signedIn = useSelector((state) => state.auth.session.signedIn);
  const [menuData, setMenuData] = useState(defaultMenuData);

  const fetchData = async () => {
    const response = await apiGetThemeConfig();
    setMenuData(prevMenuData => ({
      ...prevMenuData,
      ...response.data,
      theme: {
        ...prevMenuData.theme,
        ...response.data.theme,
      },
    }));
  };

  useEffect(() => {
    if (signedIn) {
      fetchData();
    }
  }, [signedIn]);

  let newLang = 'sq';

  if (menuData && menuData.theme.locale === 1) {
    newLang = 'sq';
  } else if (menuData && menuData.theme.locale === 2) {
    newLang = 'en';
  } else if (menuData && menuData.theme.locale === 3) {
    newLang = 'sr';
  } else {
    newLang = 'sq';
  }

  let layoutType = 'modern'

  if (menuData && menuData.theme.layoutType === 'modern') {
    layoutType = THEME_ENUM.LAYOUT_TYPE_MODERN;
  } else if (menuData && menuData.theme.layoutType === 'classic') {
    layoutType = THEME_ENUM.LAYOUT_TYPE_CLASSIC;
  } else if (menuData && menuData.theme.layoutType === 'simple') {
    layoutType = THEME_ENUM.LAYOUT_TYPE_SIMPLE;
  } else if (menuData && menuData.theme.layoutType === 'stackedSide') {
    layoutType = THEME_ENUM.LAYOUT_TYPE_STACKED_SIDE;
  } else if (menuData && menuData.theme.layoutType === 'decked') {
    layoutType = THEME_ENUM.LAYOUT_TYPE_DECKED;
  } else {
    layoutType = THEME_ENUM.LAYOUT_TYPE_MODERN;
  }

  const newlayout = {
    type: layoutType,
  };

  useEffect(() => {
    if (menuData && signedIn) {
      dispatch(setNavMode(menuData.theme.navMode))
      dispatch(setThemeColor(menuData.theme.themeColor));
      dispatch(setThemeColorLevel(menuData.theme.primaryColorLevel))
      dispatch(setLang(newLang));
      dispatch(setMode(menuData.theme.mode))
      dispatch(setLayout(newlayout.type));
    }
  }, [menuData, signedIn]);

  if (!signedIn) {
    return null;
  }

  const themeConfig = {
    themeColor: menuData.theme.themeColor,
    direction: THEME_ENUM.DIR_LTR,
    mode: THEME_ENUM.MODE_LIGHT,
    locale: newLang,
    primaryColorLevel: menuData.theme.primaryColorLevel,
    cardBordered: true,
    panelExpand: false,
    controlSize: 'md',
    navMode: menuData.theme.navMode,
    layout: {
      type: THEME_ENUM.LAYOUT_TYPE_MODERN,
      sideNavCollapse: false,
    },
  };

  return themeConfig;
}
