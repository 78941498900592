import ApiService from "./ApiService";

export async function apiSignIn(data) {
  return ApiService.fetchData({
    url: "/Administration/Login",
    method: "post",
    data,
  });
}

export async function apiSignResetPassword(data) {
  return ApiService.fetchData({
    url: "/Administration/ResetPassword",
    method: "post",
    data,
  });
}

export async function apiSignOut(data) {
  return ApiService.fetchData({
    url: "/Administration/LogOut",
    method: "post",
    data,
  });
}

export async function apiForgotPassword(data) {
  return ApiService.fetchData({
    url: "/Administration/forgot-password",
    method: "post",
    data,
  });
}

export async function apiResetPassword(data) {
  return ApiService.fetchData({
    url: "/Administration/reset-password",
    method: "post",
    data,
  });
}
