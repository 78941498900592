import ApiService from "./ApiService"

export async function apiGetThemeConfig (data) {
    return ApiService.fetchData({
        url: '/Home/GetThemeConfig',
        method: 'get',
        data
    })
}

export async function apiUpdateThemeConfig (data) {
    return ApiService.fetchData({
        url: '/Home/UpdateThemeConfig',
        method: 'put',
        data
    })
}

export async function apiGetLangList (data) {
    return ApiService.fetchData({
        url: '/Home/GetLangList',
        method: 'get',
        data
    })
}

export async function apiGetMediaExCategory (data) {
    return ApiService.fetchData({
        url: '/Home/GetMediaExCategory',
        method: 'get',
        data
    })
}


export async function apiGetTemplates (data) {
    return ApiService.fetchData({
        url: '/Home/GetTemplates',
        method: 'get',
        data
    })
}

export async function apiGetMultiLanguage (data) {
    return ApiService.fetchData({
        url: '/Home/GetMultiLanguage',
        method: 'get',
        data
    })
}

//Settings
export async function apiGetSettingsForEdit (data) {
    return ApiService.fetchData({
        url: '/Home/GetSettingsForEdit',
        method: 'get',
        data
    })
}

export async function apiUpdateSettings (data) {
    return ApiService.fetchData({
        url: '/Home/UpdateSettings',
        method: 'put',
        data
    })
}

//Dashboard
export async function apiGetDashboardStatistic (data) {
    return ApiService.fetchData({
        url: '/Home/GetDashboardStatistic',
        method: 'get',
        data
    })
}


// export async function apiGetDashboardStatisticReport (data) {
//     return ApiService.fetchData({
//         url: '/Home/GetDashboardReportsStatisticsAsync?viti=2023',
//         method: 'get',
//         data
//     })
// }


export async function apiGetDashboardStatisticReport (data) {
    return ApiService.fetchData({
        url: '/Home/GetDashboardReportsStatisticsAsync?gjuha=1',
        method: 'get',
        data
    })
}

