import React, { forwardRef, useMemo, useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Table, Pagination, Select, Checkbox } from 'components/ui'
import TableRowSkeleton from './loaders/TableRowSkeleton'
import Loading from './Loading'
import { useTable, usePagination, useSortBy, useRowSelect } from 'react-table';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const { Tr, Th, Td, THead, TBody, Sorter } = Table

const IndeterminateCheckbox = forwardRef((props, ref) => {

	const {
		indeterminate,
		onChange,
		onCheckBoxChange,
		onIndeterminateCheckBoxChange,
		...rest
	} = props

	const defaultRef = useRef()
	const resolvedRef = ref || defaultRef

	useEffect(() => {
		resolvedRef.current.indeterminate = indeterminate
	}, [resolvedRef, indeterminate])

	const handleChange = (e) => {
		onChange(e)
		onCheckBoxChange?.(e)
		onIndeterminateCheckBoxChange?.(e)
	}

	return (
		<Checkbox
			className="mb-0"
			ref={resolvedRef}
			onChange={(_, e) => handleChange(e)}
			{...rest}
		/>
	)
})

const DataTableDragDrop = (props) => {

	const {
		skeletonAvatarColumns,
		columns,
		// data,
		loading,
		onCheckBoxChange,
		onIndeterminateCheckBoxChange,
		onPaginationChange,
		onSelectChange,
		onSort,
		pageSizes,
		selectable,
		hasPagination,
		skeletonAvatarProps,
		pagingData,
		autoResetSelectedRows,
		UpdateOrder
	} = props

	const { pageSize, pageIndex, total } = pagingData

	const [data, setData] = useState([]);

	useEffect(() => {
		if (props.data) {
			setData(props.data);
		}
	}, [props.data]);

	const pageSizeOption = useMemo(() => pageSizes.map(
		number => ({ value: number, label: `${number} / page` })
	), [pageSizes])

	const handleCheckBoxChange = (checked, row) => {
		if (!loading) {
			onCheckBoxChange?.(checked, row)
		}
	}

	const handleIndeterminateCheckBoxChange = (checked, rows) => {
		if (!loading) {
			onIndeterminateCheckBoxChange?.(checked, rows)
		}
	}

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
	} = useTable(
		{
			columns,
			data,
			manualPagination: true,
			manualSortBy: true,
			autoResetSelectedRows
		},
		useSortBy,
		usePagination,
		useRowSelect,
		hooks => {
			if (selectable) {
				hooks.visibleColumns.push(columns => [
					{
						id: 'selection',
						Header: (props) => (
							<div>
								<IndeterminateCheckbox
									{...props.getToggleAllRowsSelectedProps()}
									onIndeterminateCheckBoxChange={e => handleIndeterminateCheckBoxChange(e.target.checked, props.rows)}
								/>
							</div>
						),
						Cell: ({ row }) => (
							<div>
								<IndeterminateCheckbox
									{...row.getToggleRowSelectedProps()}
									onCheckBoxChange={e => handleCheckBoxChange(e.target.checked, row.original)}
								/>
							</div>
						),
						sortable: false,
					},
					...columns,
				])
			}
		}
	)

	const handlePaginationChange = page => {
		if (!loading) {
			onPaginationChange?.(page)
		}
	}

	const handleSelectChange = value => {
		if (!loading) {
			onSelectChange?.(Number(value))
		}
	}

	const handleSort = column => {
		if (!loading) {
			const { id, isSortedDesc, toggleSortBy, clearSortBy } = column
			const sortOrder = isSortedDesc ? 'desc' : 'asc'
			toggleSortBy(!isSortedDesc)
			onSort?.({ order: sortOrder, key: id }, { id, clearSortBy })
		}
	}

	const reorderData = (startIndex, endIndex, data, setData) => {
		const newData = [...data];
		const [movedRow] = newData.splice(startIndex, 1);
		newData.splice(endIndex, 0, movedRow);
		setData(newData);
		UpdateOrder(newData);
	}
	
	const handleDragEnd = (result, data, setData) => {
		const { source, destination } = result;
		if (!destination) return;
		reorderData(source.index, destination.index, data, setData);
	}

	const onDragEnd = (result) => {
		handleDragEnd(result, data, setData);
	}

	// const onDragEnd = (result) => {
	// 	if (!result.destination) return;

	// 	const startIndex = result.source.index;
	// 	const endIndex = result.destination.index;

	// 	const newData = [...data];
	// 	const [removed] = newData.splice(startIndex, 1);
	// 	newData.splice(endIndex, 0, removed);

	// 	setData(newData);
	// };


	return (
		<Loading loading={loading && data.length !== 0} type="cover">
			<DragDropContext onDragEnd={onDragEnd}>
				<Table {...getTableProps()}>
					<THead>
						{headerGroups.map(headerGroup => (
							<Tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
									<Th {...column.getHeaderProps()}>
										{column.render('Header') && (
											column.sortable ? (
												<div className="cursor-pointer font-bold text-slate-500 dark:text-neutral-100" onClick={() => handleSort(column)}>
													{column.render('Header')}
													<span>
														<Sorter sort={column.isSortedDesc} />
													</span>
												</div>
											)
												:
												(
													<div className='font-bold text-slate-500 dark:text-neutral-100'>{column.render('Header')}</div>
												)
										)}
									</Th>
								))}
							</Tr>
						))}
					</THead>
					{
						loading && data.length === 0 ?
							(
								<TableRowSkeleton
									columns={columns.length}
									rows={pagingData.pageSize}
									avatarInColumns={skeletonAvatarColumns}
									avatarProps={skeletonAvatarProps}
								/>
							)
							:
							(
								<Droppable droppableId="table">
									{(provided, snapshot) => (
										<TBody {...getTableBodyProps()} {...provided.droppableProps} ref={provided.innerRef}>
											{page.map((row, i) => {
												prepareRow(row)
												return (
													<Draggable key={row.id} draggableId={row.id} index={i}>
														{(provided, snapshot) => (
															<Tr {...row.getRowProps()} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
																{row.cells.map(cell => {
																	return <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
																})}
															</Tr>
														)}
													</Draggable>
												)
											})}
											{provided.placeholder}
										</TBody>
									)}
								</Droppable>
							)
					}
				</Table>
			</DragDropContext>
			{hasPagination === true &&
				<div className="flex flex-col xl:flex-row lg:flex-row md:flex-row gap-5 xl:gap-0 lg:gap-0 md:gap-0 items-center p-4 border-t-[1px] dark:border-[#4B5563] justify-between mt-4">
					<Pagination
						pageSize={pageSize}
						currentPage={pageIndex}
						total={total}
						onChange={handlePaginationChange}
					/>
					<div style={{ minWidth: 130 }}>
						<Select
							size="sm"
							menuPlacement="top"
							isSearchable={false}
							value={pageSizeOption.filter(option => option.value === pageSize)}
							options={pageSizeOption}
							onChange={option => handleSelectChange(option.value)}
						/>
					</div>
				</div>
			}

		</Loading>
	)
}

DataTableDragDrop.propTypes = {
	columns: PropTypes.array,
	data: PropTypes.array,
	loading: PropTypes.bool,
	hasPagination: PropTypes.bool,
	onCheckBoxChange: PropTypes.func,
	onIndeterminateCheckBoxChange: PropTypes.func,
	onPaginationChange: PropTypes.func,
	onSelectChange: PropTypes.func,
	onSort: PropTypes.func,
	pageSizes: PropTypes.arrayOf(PropTypes.number),
	selectable: PropTypes.bool,
	skeletonAvatarColumns: PropTypes.arrayOf(PropTypes.number),
	skeletonAvatarProps: PropTypes.object,
	pagingData: PropTypes.shape({
		total: PropTypes.number,
		pageIndex: PropTypes.number,
		pageSize: PropTypes.number,
	}),
	autoResetSelectedRows: PropTypes.bool
}

DataTableDragDrop.defaultProps = {
	pageSizes: [10, 25, 50, 100],
	pagingData: {
		total: 0,
		pageIndex: 1,
		pageSize: 10,
	},
	data: [],
	columns: [],
	selectable: false,
	loading: false,
	hasPagination: true,
	autoResetSelectedRows: true,
}


export default DataTableDragDrop