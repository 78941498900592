import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
    name: 'auth/session',
    initialState: {
        token: '',
        signedIn: false,
        website: false,
        expiration: undefined
    },
    reducers: {
        onSignInSuccess: (state, action) => {
            state.signedIn = true
            state.token = action.payload.token
            state.expiration = action.payload.expiration
        },
        onSignOutSuccess: (state) => {
            state.signedIn = false
            state.token = ''
        },
        setToken: (state, action) => {
            state.token = action.payload.token
            state.expiration = action.payload.expiration
        },
        setSignedIn: (state, action) => {
            state.signedIn = action.payload
        },
        setWebsite: (state, action) => {
            state.website = action.payload
        }
    },
})

export const { onSignInSuccess, onSignOutSuccess, setToken, setSignedIn, setWebsite } = sessionSlice.actions

export default sessionSlice.reducer